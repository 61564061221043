import React, { useState } from 'react';
import './BlogAndNews.css'; // Importar o arquivo CSS para estilização
import { postsData } from './Post'; // Importa os dados do Post.js

const BlogAndNews = () => {
    const [selectedPost, setSelectedPost] = useState(null);

    const handleReadMore = (post) => {
        setSelectedPost(post);
    };

    const handleBackToList = () => {
        setSelectedPost(null);
    };

    return (
        <div className="blog-container">
            <h1>Blog e Notícias</h1>

            {selectedPost ? (
                <div className="post-detail">
                    <h2>{selectedPost.title}</h2>
                    <p className="author">Por {selectedPost.author}</p>
                    <p className="date">{new Date(selectedPost.date).toLocaleDateString()}</p>
                    <p>{selectedPost.content}</p>
                    <button className="back-button" onClick={handleBackToList}>Voltar</button>
                </div>
            ) : (
                <div className="post-list">
                    {postsData.length === 0 ? (
                        <p>Não há artigos ou notícias disponíveis no momento.</p>
                    ) : (
                        postsData.map(post => (
                            <div key={post.id} className="post-item">
                                <h2>{post.title}</h2>
                                <p className="author">Por {post.author}</p>
                                <p className="date">{new Date(post.date).toLocaleDateString()}</p>
                                <p>{post.summary}</p>
                                <button className="read-more" onClick={() => handleReadMore(post)}>Leia mais</button>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default BlogAndNews;
