import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { IoMdCloseCircle } from 'react-icons/io';

const AdoptMe = ({ pet, onClose }) => {
  const [reason, setReason] = useState(''); // Estado para armazenar o motivo da doação
  const [country, setCountry] = useState('Brasil'); // Estado fixado para o país
  const [state, setState] = useState(''); // Estado para armazenar o estado
  const [city, setCity] = useState(''); // Estado para armazenar a cidade
  const [neighborhood, setNeighborhood] = useState(''); // Estado para armazenar o bairro
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado de carregamento
  const [states, setStates] = useState([]); // Lista de estados
  const [cities, setCities] = useState([]); // Lista de cidades

  useEffect(() => {
    // Carregar estados do IBGE
    const fetchStates = async () => {
      try {
        const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome');
        setStates(response.data);
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
      }
    };

    fetchStates();
  }, []);

  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setState(selectedState);
    setCity(''); // Limpar cidade ao mudar estado

    // Carregar cidades do estado selecionado
    try {
      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState}/municipios`
      );
      setCities(response.data);
    } catch (error) {
      console.error('Erro ao carregar cidades:', error);
    }
  };

  const handleSubmit = async () => {
    // Validação de todos os campos obrigatórios
    if (!reason.trim() || !state || !city || !neighborhood.trim()) {
      alert('Por favor, preencha todos os campos.');
      return;
    }

    setIsSubmitting(true);
    try {
      const petImagePath = pet.pet_3x4
        ? `https://vidapet.tech/vidapet/uploads/${pet.pet_3x4.split('/').pop()}`
        : 'default_image_path.jpg';

      const response = await axios.post(
        'https://vidapet.tech/api/DB/vidapettech/adoptme.php',
        {
          petId: pet.petId, // Verifique se o petId está correto
          reason,
          country,
          state,
          city,
          neighborhood,
          image_path: petImagePath,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200 && response.data.success) {
        alert('Pet colocado para adoção com sucesso!');
        onClose(); // Fechar o modal após o sucesso
      } else {
        alert('Houve um problema ao tentar colocar o pet para adoção: ' + response.data.message);
      }
    } catch (error) {
      alert('Erro ao enviar dados. Tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalBackground>
      <ModalContent>
        <Header>
          <Title>Colocar {pet.petName} para Doação</Title>
          <CloseButton onClick={onClose} />
        </Header>

        <Form>
          <Label>Motivo da Doação:</Label>
          <TextArea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Descreva o motivo pelo qual você deseja doar o pet..."
          />

          <Label>País:</Label>
          <Input value={country} readOnly />

          <Label>Estado:</Label>
          <Select value={state} onChange={handleStateChange}>
            <option value="">Selecione o estado</option>
            {states.map((estado) => (
              <option key={estado.id} value={estado.sigla}>
                {estado.nome}
              </option>
            ))}
          </Select>

          <Label>Cidade:</Label>
          <Select value={city} onChange={(e) => setCity(e.target.value)} disabled={!state}>
            <option value="">Selecione a cidade</option>
            {cities.map((cidade) => (
              <option key={cidade.id} value={cidade.nome}>
                {cidade.nome}
              </option>
            ))}
          </Select>

          <Label>Bairro:</Label>
          <Input
            value={neighborhood}
            onChange={(e) => setNeighborhood(e.target.value)}
            placeholder="Informe o bairro onde o pet está localizado..."
          />

          <ButtonContainer>
            <Button onClick={onClose} disabled={isSubmitting}>
              Cancelar
            </Button>
            <Button primary onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? 'Enviando...' : 'Confirmar Doação'}
            </Button>
          </ButtonContainer>
        </Form>
      </ModalContent>
    </ModalBackground>
  );
};

// Estilos para o modal e botões
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 18px;
  color: #333;
`;

const CloseButton = styled(IoMdCloseCircle)`
  font-size: 24px;
  cursor: pointer;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  font-size: 16px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 20px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 20px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  resize: none;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled.button`
  background-color: ${(props) => (props.primary ? '#007bff' : '#f5f5f5')};
  color: ${(props) => (props.primary ? 'white' : '#333')};
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${(props) => (props.primary ? '#0056b3' : '#e0e0e0')};
  }
`;

export default AdoptMe;
