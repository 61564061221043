import React from 'react';
import './Cartalogo.css'; // Importando o CSS

const Gallery = ({ photos }) => {
    return (
        <div className="gallery">
            {photos.map((photo, index) => (
                <div className="gallery-item" key={index}>
                    <img src={photo} alt={`Foto ${index + 1}`} />
                </div>
            ))}
        </div>
    );
};

export default Gallery;
