import React from 'react';
import petListImg from '../../imagens/petlist.png'; // Substitua com o caminho correto da imagem
import carteirinhaImg from '../../imagens/carteirinha1.png'; // Substitua com o caminho correto da imagem
import vacinacoesImg from '../../imagens/vacinacoes1.png'; // Substitua com o caminho correto da imagem
import consultasImg from '../../imagens/consultas1.png'; // Substitua com o caminho correto da imagem
import examesImg from '../../imagens/exames1.png'; // Substitua com o caminho correto da imagem
import qrCodeImg from '../../imagens/qrcode1.png'; // Substitua com o caminho correto da imagem

const DashboardHome = ({ onAccess }) => {
  return (
    <div style={styles.dashboardHome}>
      <p style={styles.paragraph}>
        Cadastre-se Grátis para Gerenciar todas as informações de saúde dos seus pets de forma prática e organizada. Abaixo estão as funcionalidades que você pode acessar:
      </p>

      <div style={styles.infoSection}>
        {infoItems.map((item, index) => (
          <div key={index} style={styles.infoItem}>
            <img src={item.img} alt={item.alt} style={styles.infoImage} />
            <div style={styles.infoContent}>
              <p style={styles.paragraph}><strong>{item.title}</strong></p>
              <p style={styles.paragraph}>{item.description}</p>
              <button style={styles.button} onClick={() => onAccess(item.title)}>
                Acessar Pets
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const infoItems = [
  {
    img: petListImg,
    alt: "Lista de Pets",
    title: "Lista de Pets",
    description: "Visualize e gerencie todos os seus pets cadastrados.",
  },
  {
    img: carteirinhaImg,
    alt: "Carteirinha Digital",
    title: "Cadastro da Carteirinha Digital",
    description: "Crie uma carteirinha digital com todas as informações essenciais do seu pet.",
  },
  {
    img: vacinacoesImg,
    alt: "Vacinações",
    title: "Vacinações",
    description: "Registre e acompanhe o histórico de vacinações dos seus pets.",
  },
  {
    img: consultasImg,
    alt: "Consultas",
    title: "Consultas",
    description: "Agende e registre consultas veterinárias.",
  },
  {
    img: examesImg,
    alt: "Exames",
    title: "Exames",
    description: "Acompanhe e registre todos os exames realizados pelos seus pets.",
  },
  {
    img: qrCodeImg,
    alt: "QR Code",
    title: "Impressão",
    description: "Imprima a carteirinha digital completa ou apenas o QR Code do seu pet para facilitar a identificação.",
  },
];

const styles = {
  dashboardHome: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
  },
  title: {
    color: '#333',
    textAlign: 'center',
    marginBottom: '20px',
  },
  paragraph: {
    color: '#555',
    fontSize: '16px',
    lineHeight: '1.6',
  },
  infoSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  infoItem: {
    display: 'flex', // Mudado para usar flexbox
    flexDirection: 'row', // Itens lado a lado
    alignItems: 'center', // Centraliza verticalmente
    flex: '1 1 30%', // Flex para responsividade
    margin: '20px',
    backgroundColor: '#fff',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  infoContent: {
    textAlign: 'left',
    marginLeft: '10px', // Espaço entre a imagem e o texto
  },
  infoImage: {
    maxWidth: '100px', // Tamanho fixo para a imagem
    height: 'auto',
    borderRadius: '8px',
  },
  button: {
    backgroundColor: '#ff9800',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    marginTop: '10px', // Espaço acima do botão
  },
};

export default DashboardHome;
