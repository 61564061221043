export const postsData = [
    {
        id: 1,
        title: 'VidaPet.Tech entra para o programa de aceleração do Ibmec Hubs Rio de Janeiro',
        author: 'Equipe VidaPet.Tech',
        date: '2024-10-01',
        summary: 'VidaPet.Tech foi selecionada para o programa de aceleração do Ibmec Hubs no Rio de Janeiro, um marco importante para a startup.',
        content: `
        O mundo das startups é altamente competitivo, e ser selecionado para um programa de aceleração pode significar a diferença entre o sucesso e o fracasso de um negócio. Com grande orgulho, anunciamos que a VidaPet.Tech foi escolhida para participar do prestigiado programa de aceleração do Ibmec Hubs Rio de Janeiro, um dos hubs de inovação mais respeitados no Brasil.

        Este marco não só valida o trabalho árduo da equipe VidaPet.Tech, mas também demonstra o impacto que a nossa plataforma está gerando no setor de tecnologia voltado para a saúde animal. Nossa missão sempre foi clara: melhorar a vida dos pets e facilitar a gestão de saúde para seus donos e profissionais do setor. Através da tecnologia, pretendemos simplificar o acesso a informações críticas sobre vacinas, check-ups, exames e tratamentos necessários para garantir que os pets estejam sempre saudáveis.

        O programa de aceleração do Ibmec Hubs oferece uma oportunidade única para impulsionar ainda mais a nossa proposta. Ao longo dos próximos meses, a VidaPet.Tech terá acesso a mentoria de alto nível, suporte em áreas estratégicas, como marketing e vendas, além de uma vasta rede de contatos que poderá abrir portas para novas parcerias, investimentos e expansão.

        Os participantes do programa são selecionados com base em seu potencial de inovação e impacto no mercado. A VidaPet.Tech foi reconhecida por sua solução pioneira no campo da gestão de saúde animal, oferecendo uma plataforma intuitiva e eficaz para donos de pets monitorarem o bem-estar de seus animais de estimação.

        Estamos ansiosos para aproveitar ao máximo esta oportunidade, aprendendo e crescendo com o apoio dos especialistas e empreendedores do Ibmec Hubs. O nosso objetivo é escalar a VidaPet.Tech para novos patamares, proporcionando um serviço ainda mais completo e personalizado para nossos usuários. Em breve, novos recursos serão adicionados à plataforma, e novas funcionalidades tornarão a experiência do usuário ainda mais rica e eficiente.

        A VidaPet.Tech agradece a todos os parceiros, investidores e, principalmente, aos nossos usuários que confiaram e continuam a confiar na nossa proposta. Juntos, vamos transformar a forma como a saúde pet é gerida no Brasil e no mundo.
        `
    },
    {
        id: 2,
        title: 'Quantas vacinas um cão precisa tomar durante sua vida?',
        author: 'Dr. João Silva, Veterinário',
        date: '2024-09-15',
        summary: 'Saiba quais são as principais vacinas que um cão precisa tomar e com que frequência.',
        content: `
        Vacinar um cão é uma das responsabilidades mais importantes de qualquer tutor de pet. As vacinas não só protegem os cães contra doenças potencialmente mortais, como também ajudam a prevenir a propagação de doenças entre animais e até humanos. No entanto, muitos donos de cães se perguntam quantas vacinas seus animais precisam tomar ao longo de suas vidas e com que frequência.

        A resposta varia de acordo com o tipo de vacina, a idade do cão e os riscos ambientais a que ele está exposto. De modo geral, as vacinas são divididas em dois grupos: as vacinas essenciais (ou obrigatórias) e as vacinas opcionais.

        **Vacinas essenciais**
        As vacinas essenciais são aquelas que todo cão deve receber, independentemente do ambiente em que vive ou do seu estilo de vida. As principais são:
        - **Vacina múltipla (V8 ou V10)**: Esta é uma das vacinas mais importantes para os cães e protege contra várias doenças graves, como cinomose, parvovirose, hepatite infecciosa canina, leptospirose e coronavirose. A vacinação começa ainda quando o cão é filhote, com 6 a 8 semanas de vida, e deve ser repetida a cada 3 a 4 semanas até o cão completar 16 semanas. Após esse período, são feitos reforços anuais para garantir a proteção contínua.
        - **Antirrábica**: A vacina contra a raiva é fundamental tanto para a saúde dos cães quanto para a segurança das pessoas, pois a raiva é uma zoonose (ou seja, pode ser transmitida para humanos). A primeira dose é dada por volta dos 3 meses de idade, com reforços anuais.

        **Vacinas opcionais**
        Além das vacinas essenciais, existem outras vacinas que podem ser recomendadas dependendo do estilo de vida e das condições de saúde do cão. Entre as mais comuns estão:
        - **Vacina contra a tosse dos canis (gripe canina)**: A tosse dos canis é altamente contagiosa, especialmente em ambientes onde muitos cães convivem, como pet shops, hotéis para cães ou parques. Esta vacina pode ser aplicada a partir de 8 semanas de idade e requer reforços anuais.
        - **Vacina contra giárdia**: A giárdia é um parasita que pode causar diarreia severa e, em alguns casos, desidratação perigosa. Embora a vacinação contra giárdia não seja obrigatória, pode ser recomendada em áreas onde a doença é comum.
        - **Vacina contra leishmaniose**: Dependendo da região onde o cão vive, a vacina contra leishmaniose pode ser crucial, pois a doença é grave e pode ser transmitida pela picada de mosquitos. Cães em áreas de risco devem receber a primeira dose da vacina ainda jovens, com reforços anuais.

        **Vacinas ao longo da vida**
        O cronograma de vacinação de um cão começa nos primeiros meses de vida e continua ao longo de toda a vida do animal. Reforços anuais são necessários para garantir que a proteção seja mantida, especialmente para vacinas como a múltipla e a antirrábica. À medida que o cão envelhece, o veterinário poderá ajustar o cronograma de vacinação de acordo com as necessidades de saúde do animal e o risco de exposição a doenças.

        Além das vacinas, é importante realizar consultas veterinárias regulares para garantir que o cão esteja saudável e que seu cronograma de vacinação esteja em dia. A vacinação é uma medida preventiva simples, mas extremamente eficaz, para proteger a vida do seu cão e garantir que ele viva uma vida longa e saudável.

        **Conclusão**
        Cuidar da saúde de um cão envolve muito mais do que alimentação e carinho. Garantir que todas as vacinas estão em dia é fundamental para evitar doenças sérias e proteger tanto os cães quanto seus donos. Consultar um veterinário regularmente para discutir as necessidades de vacinação é a melhor forma de manter seu cão saudável ao longo da vida.
        `
    }
];
